import { defineComponent } from 'vue';
import ProfileSkills from '@/core/components/account/ProfileSkills.vue';
export default defineComponent({
    name: 'skills-form-component',
    components: {
        ProfileSkills,
    },
    props: {
        record: {
            type: Object,
            required: true,
        },
        submitting: Boolean,
        categories: {
            type: Array,
            required: true,
        },
        rawCategories: {
            type: Array,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        closableTag: {
            type: Boolean,
        }
    },
    data() {
        return {
            selectSkill: [],
        };
    },
    computed: {
        currentRecord() {
            return this.record;
        },
        getSkills() {
            const data = [];
            this.record.categories.forEach((category) => {
                const catObj = {};
                const parentCategory = this.rawCategories.find((c) => c.id === category[0]);
                if (parentCategory && parentCategory.subCategories) {
                    catObj.parentCategory = JSON.parse(JSON.stringify(parentCategory));
                    delete catObj.parentCategory.children;
                    catObj.subCategory = parentCategory.subCategories.find((sub) => sub.id === category[1]);
                    data.push(catObj);
                }
            });
            return data;
        }
    },
    methods: {
        getSubCategory(ids) {
            let subCategory;
            const parentCategory = this.categories.find((c) => c.value === ids[0]);
            if (parentCategory && parentCategory.children) {
                subCategory = parentCategory.children.find((sub) => sub.value === ids[1]);
                return subCategory.label;
            }
            return subCategory;
        },
        removeSubCategory(subCategoryId) {
            const categories = JSON.parse(JSON.stringify(this.currentRecord.categories));
            const index = categories.findIndex((c) => c[1] === subCategoryId);
            categories.splice(index, 1);
            const newCategories = categories;
            this.currentRecord.categories = newCategories;
        },
        submitForm() {
            this.$emit('submit', this.currentRecord);
        }
    }
});
