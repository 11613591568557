import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import UserVerificationStatus from '@/core/components/account/UserVerificationStatus.vue';
import CardWrapper from '@/core/components/ui/CardWrapper.vue';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import SkillsFormComponent from '@/modules/account-details/components/SkillsFormComponent.vue';
import { PROJECTS_STORE } from '../../../store/modules/projects';
import { USERS_STORE } from '../../../store/modules/users';
export default defineComponent({
    name: 'skills-page',
    components: {
        CardWrapper,
        UserVerificationStatus,
        SkillsFormComponent,
    },
    data() {
        return {
            submittingForm: false,
            UserVerificationStatusEnum,
            userSkills: {
                categories: []
            },
            skillsSuccess: false,
            userSkillsVerificationStatus: null,
        };
    },
    computed: {
        ...mapGetters(PROJECTS_STORE, ['categories']),
        ...mapGetters(USERS_STORE, ['user']),
        rawCategories() {
            return this.categories;
        },
        availableCategories() {
            if (this.categories && this.categories.length) {
                return this.categories.map((category) => {
                    return {
                        value: category.id,
                        label: category.tpLabel,
                        children: category.subCategories.map((sub) => {
                            return { value: sub.id, label: sub.label };
                        }),
                    };
                });
            }
            return [];
        },
        disabled() {
            return this.user.userVerificationStatus === UserVerificationStatusEnum.PENDING_VERIFICATION;
        }
    },
    methods: {
        ...mapActions(PROJECTS_STORE, ['getCategories']),
        ...mapActions(USERS_STORE, ['getUserVerificationStatus', 'getUserSkills', 'saveUserSkills', 'getAccountStatuses']),
        removeSkill(skill) {
            const { categories } = this.userSkills;
            categories.splice(categories.indexOf(skill), 1);
        },
        setUserSkillsRecord(payload) {
            const userSkillsRecord = {
                categories: [],
            };
            if (payload.length) {
                payload.forEach((record) => {
                    userSkillsRecord.categories.push([
                        record.subCategory.category.id,
                        record.subCategory.id
                    ]);
                });
                this.userSkills = userSkillsRecord;
            }
        },
        parseDataUserSkills(formData) {
            const payload = {
                subCategoryId: [],
            };
            if (formData.categories) {
                formData.categories.forEach((value) => {
                    payload.subCategoryId.push(value[1]);
                });
            }
            return payload;
        },
        submit(formData) {
            this.submittingForm = true;
            const userSkills = this.parseDataUserSkills(formData);
            this.saveUserSkills({
                userId: this.user.id,
                skills: userSkills
            })
                .then(async () => {
                this.$notify.success({
                    message: 'Skill categories successfully saved.'
                });
                await this.getAccountStatuses();
                this.$router.push({ name: 'account/details' });
            })
                .catch((e) => {
                this.$notify.error({
                    message: e || 'Error submitting Skill categories details.'
                });
            })
                .finally(() => {
                this.submittingForm = false;
            });
        }
    },
    async created() {
        await this.getCategories();
        const userSkills = await this.getUserSkills(this.user.id);
        // set the user skills record
        this.setUserSkillsRecord(userSkills);
        const userVerificationStatus = await this.getUserVerificationStatus(this.user.id);
        this.userSkillsVerificationStatus = userVerificationStatus || null;
    }
});
